import React, { Component, Suspense } from 'react';

const NavbarPage = React.lazy(() => import('./Navbar'))
const Section = React.lazy(() => import('./section'))
const Time = React.lazy(() => import('./Time'))
const About = React.lazy(() => import('./About'))
const Benefit = React.lazy(() => import('./Benefit'))
const University = React.lazy(() => import('./University'))
const Persiapan = React.lazy(() => import('./Persiapan'))
const FormRegister = React.lazy(() => import('./FormRegister'))
const SuperStudent = React.lazy(() => import('./SuperStudent'))
const Footer = React.lazy(() => import('./Footer'))

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems: [
                { id: 1, idnm: "home", navheading: "Home" },
                { id: 2, idnm: "time", navheading: "Time" },
                { id: 3, idnm: "about", navheading: "About" },
                { id: 4, idnm: "benefit", navheading: "Benefit" },
                { id: 5, idnm: "persiapan", navheading: "Persiapan" },
                { id: 6, idnm: "register", navheading: "Register" },
            ],
            pos: document.documentElement.scrollTop,
            imglight: true,
            navClass: ""
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > this.state.pos) {
            this.setState({ navClass: "darkheader", imglight: false });
        }
        else {
            this.setState({ navClass: "", imglight: true });
        }
    }

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">Loading...</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <Suspense fallback={this.Loader()} >

                    {/* Importing Navbar */}
                    <NavbarPage navItems={this.state.navItems} navclassName={this.state.navClass} />

                    {/* Importing section */}
                    <Section />

                    <Time />

                    {/* Importing about */}
                    <About />

                    {/* Importing benefit */}
                    <Benefit />

                    {/* Importing persiapan */}
                    <Persiapan />

                    {/* Importing Form */}
                    <FormRegister />

                    <SuperStudent />

                    <University />

                    {/* Importing footer */}
                    <Footer />
                </Suspense>
            </>
        )
    }
}

export default Index